import './Banner.scss'


export const Banner = () => {
  return (
    <div className="Banner">
      <h1 className='Banner__title'>
        Be Visible. 
        <br />
        Get Connected.
        <br />
        Make Business
      </h1>
      <p className='Banner__p'>
        The only platform you will ever need to enter new markets faster and help grow your sales by connecting directly with resellers:
      <br/>
        integrated apps, kept simpe to solve logistics, excise and much more to ship you rproduct across European borders.
      </p>
      <div>
        <button className='Banner__button'>Start Now - its Free</button>
        <button className='Banner__button Banner__button__gray'>Meet an Expect</button>
      </div>
    </div>
  )
}

export default Banner