import { HomePage } from './HomePage/HomePage'
import { Route, Routes } from 'react-router-dom';
import { SubscriptionsPage } from './Subscribtion/SubscriptionPage'
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute'
import { WareHousesPage } from '../pages/WareHouses/WareHousesPage'
import { LocationPage } from '../pages/Location/LocationPage'
import { WareHousesDetailPage } from '../pages/WareHousesDetailPage/WareHousesDetailPage'
import { ProductsPage } from './Products/ProductsPage'
import { ProfilePage } from './Profile/ProfilePage'
import { ProductDetailPage } from './ProductDetailPage/ProductDetailPage'
import { CocktailsDetailPage } from './CocktailsDetailPage/CocktailsDetailPage';
import { BasketPage } from './Basket/BasketPage'
import { OrdersPage } from './Orders/OrdersPage';
import { LeadPage } from './Leads/Leads'

export const Pages = () => {

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
          path="/subscriptions"
        element={<PrivateRoute element={<SubscriptionsPage isButton={true} />} isAuthenticated={localStorage.getItem('user')} />}
      />
       <Route
          path="/ware-houses"
        element={<PrivateRoute element={<WareHousesPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
          path="/locations"
        element={<PrivateRoute element={<LocationPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path="/ware-house/:number"
        element={<PrivateRoute element={<WareHousesDetailPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path="/products"
        element={<PrivateRoute element={<ProductsPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path="/profile"
        element={<PrivateRoute element={<ProfilePage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/product/:number'
        element={<PrivateRoute element={<ProductDetailPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/cocktails/:number'
        element={<PrivateRoute element={<CocktailsDetailPage  />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/basket/'
        element={<PrivateRoute element={<BasketPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/orders/'
        element={<PrivateRoute element={<OrdersPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/lead-list/'
        element={<PrivateRoute element={<LeadPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
  </Routes>
  )
}
export default Pages;