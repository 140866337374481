import React, { useState, useEffect } from 'react';
import './CocatilDetail.scss';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { StarRating, ReadOnlyStarRating } from '../../../components/StarRating/StarRating';

export const CocatilDetail = ({ currentCocktail, onRatingSubmit }) => {
  const [hasReviewed, setHasReviewed] = useState(false);

  useEffect(() => {
    const checkUserReview = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem('user')).id;
        const response = await requestInstance.get(`${BASE_URL}reviews/cocktails/`, {
          params: {
            from_user: userId,
            to_cocktails: currentCocktail.id
          }
        });
        if (response.data.results.length > 0) {
          setHasReviewed(true);
        }
      } catch (error) {
        console.error('Failed to check user review:', error);
      }
    };

    checkUserReview();
  }, [currentCocktail.id]);

  return (
    <div className='CocatilDetail'>
      <img src={currentCocktail.picture} alt={currentCocktail.title} className='CocatilDetail__img' />
      <h1 className='CocatilDetail__title'>{currentCocktail.title}</h1>
      <ReadOnlyStarRating rating={currentCocktail.average_rating} />
      <h2 className='CocatilDetail__title_2'>Ingredients</h2>
      <ul className='CocatilDetail__ingredients'>
        {currentCocktail.ingredients.map((ingredient) => (
          <li key={ingredient.id} className='CocatilDetail__text'>{ingredient.name}</li>
        ))}
      </ul>
      <h2 className='CocatilDetail__title_2'>How to make</h2>
      <p className='CocatilDetail__text'>{currentCocktail.description}</p>
      {!hasReviewed &&
      <>
      <h2 className='CocatilDetail__title_2'>Rate this cocktail</h2>
        <StarRating rating={0} currentCocktail={currentCocktail} setHasReviewed={setHasReviewed} onRatingSubmit={onRatingSubmit}/>
        
        </>}
    </div>
  );
};

export default CocatilDetail;