import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProductForm.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import AsyncSelect from 'react-select/async';
import ImageForm from '../../../components/ImageForm/ImageForm';

export const ProductForm = ({ setCurrentForm, productId = null }) => {
  const [product, setProduct] = useState({
    name: '',
    alcohol_percentage: 0,
    bottle_size: '',
    batch_nr: '',
    production_year: new Date().getFullYear(),
    age: 0,
    proven_sustainable_product_line: false,
    available_for_sampling_service: false,
    price: 0,
    payment_conditions: '',
    numbers_in_stock: 0,
    description: '',
    makers: '',
    category_id: 0,
    company_id: JSON.parse(localStorage.getItem('user')).company.id,
    warehouses_contain: [],
    currency: 'USD',
  });

  const [initialProduct] = useState({
    name: '',
    alcohol_percentage: 0,
    bottle_size: '',
    batch_nr: '',
    production_year: new Date().getFullYear(),
    age: 0,
    proven_sustainable_product_line: false,
    available_for_sampling_service: false,
    price: 0,
    payment_conditions: '',
    numbers_in_stock: 0,
    description: '',
    makers: '',
    category_id: 0,
    company_id: JSON.parse(localStorage.getItem('user')).company.id,
    warehouses_contain: [],
    currency: 'USD',
  });
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesUrl = `${BASE_URL}products/categories`;
        const warehousesUrl = `${BASE_URL}ware-houses/?company_id=${product.company_id}`;
        
        const [categoriesResponse, warehousesResponse] = await Promise.all([
          requestInstance.get(categoriesUrl),
          requestInstance.get(warehousesUrl)
        ]);

        setCategories(categoriesResponse.data.results);
        setWarehouses(warehousesResponse.data.results);
        if (productId) {
          const productUrl = `${BASE_URL}products/${productId}/`;
          const productResponse = await requestInstance.get(productUrl);
          setProduct(productResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [productId, product.company_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProduct({ ...product, [name]: checked });
  };

  const handleCheckboxChangeW = (selectedOptions) => {
    const selectedWarehouses = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setProduct({ ...product, warehouses_contain: selectedWarehouses });
  };


  const handleSave = async () => {
    try {
      const url = productId ? `${BASE_URL}products/${productId}/` : `${BASE_URL}products/`;
      const method = productId ? 'patch' : 'post';
      console.log(product);
      const response = await requestInstance[method](url, product);
      console.log(response);
      toast.success('Product saved successfully!');
      setProduct(initialProduct);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400 && error.response.data) {
        const errorMessages = error.response.data;
        for (const field in errorMessages) {
          if (errorMessages.hasOwnProperty(field)) {
            errorMessages[field].forEach(message => {
              if (field === 'category_id' && message.includes('Invalid pk "0" - object does not exist.')) {
                toast.error('category: This field may not be blank.');
              } else {
                toast.error(`${field}: ${message}`);
              }
            });
          }
        }
      } else {
        toast.error('Failed to save product.');
      }
    }
  };

  const loadWarehouses = (inputValue, callback) => {
    const filteredOptions = warehouses.filter(warehouse =>
      warehouse.name.toLowerCase().includes(inputValue.toLowerCase())
    ).map(warehouse => ({ value: warehouse.id, label: warehouse.name }));
    callback(filteredOptions);
  };

  return (
    <div className='ProductForm'>
      <ToastContainer />
      <div className='ProductForm__header'>
        <h2 className='ProductForm__title'>{productId ? 'Update Product' : 'Create Product'}</h2>
        <div className='ProductForm__header__buttons'>
          <button className='ProductForm__header__button ProductForm__header__button--cancel' onClick={() => setCurrentForm('products')}>Back</button>
          <button className='ProductForm__header__button ProductForm__header__button--save' onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className='ProductForm__section'>
        {productId && <ImageForm objectId={productId} objectType='product' /> }
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Name</h3>
            <input className='ProductForm__input' name='name' value={product.name} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Alcohol Percentage</h3>
            <input className='ProductForm__input' type='number' name='alcohol_percentage' value={product.alcohol_percentage} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Bottle Size</h3>
            <input className='ProductForm__input' name='bottle_size' value={product.bottle_size} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Batch Number</h3>
            <input className='ProductForm__input' name='batch_nr' value={product.batch_nr} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Production Year</h3>
            <input className='ProductForm__input' type='number' name='production_year' value={product.production_year} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Age</h3>
            <input className='ProductForm__input' type='number' name='age' value={product.age} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Price</h3>
            <input className='ProductForm__input' type='number' name='price' value={product.price} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Payment Conditions</h3>
            <input className='ProductForm__input' name='payment_conditions' value={product.payment_conditions} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Numbers in Stock</h3>
            <input className='ProductForm__input' type='number' name='numbers_in_stock' value={product.numbers_in_stock} onChange={handleChange} />
          </div>

          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Currency</h3>
            <input className='ProductForm__input' name='currency' value={product.currency} onChange={handleChange} />
          </div>

        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Makers</h3>
            <input className='ProductForm__input' name='makers' value={product.makers} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Category</h3>
            <select className='ProductForm__select' name='category_id' value={product.category_id} onChange={handleChange}>
            <option value={0}>---</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='ProductForm__row ProductForm__field--long'>
          <div className='ProductForm__field'>
        <h3 className='ProductForm__field_title'>Warehouses Contain</h3>
        <AsyncSelect
          isMulti
          cacheOptions
          defaultOptions={warehouses.map(warehouse => ({ value: warehouse.id, label: warehouse.name }))}
          loadOptions={loadWarehouses}
          onChange={handleCheckboxChangeW}
          value={warehouses.filter(warehouse => product.warehouses_contain.includes(warehouse.id)).map(warehouse => ({ value: warehouse.id, label: warehouse.name }))}
        />
      </div>
        </div>
        <div className='ProductForm__field--description'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Description</h3>
            <textarea className='ProductForm__input' name='description' value={product.description} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm--checkbox'>
          <div className='ProductForm__field--checkbox'>
            <h3 className='ProductForm__field_title'>Proven Sustainable Product Line</h3>
            <input className='ProductForm__input--checkbox' type='checkbox' name='proven_sustainable_product_line' checked={product.proven_sustainable_product_line} onChange={handleCheckboxChange} />
          </div>
          <div className='ProductForm__field--checkbox' >
            <h3 className='ProductForm__field_title'>Available for Sampling Service</h3>
            <input className='ProductForm__input--checkbox' type='checkbox' name='available_for_sampling_service' checked={product.available_for_sampling_service} onChange={handleCheckboxChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;