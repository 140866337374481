import { useState } from "react";
import './EmailConfirmationForm.scss';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../api/constants'
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { saveTokens } from '../../../services/TokenService/tokens'
import { useUser } from '../../../context/UserContext';

export const EmailConfirmationForm = ({ setIsEmailConfirmationFormVisible }) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [errors, setErrors] = useState('')
  const { setUser } = useUser();
  const navigate = useNavigate();

  const handleChange = (element, index) => {
    setErrors('')
    if (isNaN(element.value)) return false;

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = BASE_URL + 'auth/registration-3-step/'
      const result = await requestInstance.post(
        url, {
          email: localStorage.getItem('email'),
          code: code.join(""),
          company_id: localStorage.getItem('companyID')
      });
      if (result) {
        console.log(result.data)
        saveTokens(result.data);
        setIsEmailConfirmationFormVisible(false)
        const url = BASE_URL + 'auth/me/'
        const response = await requestInstance.get(url);
        const userData = response.data;
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData)
        navigate('/subscriptions');
        window.location.reload();
      } else {
        console.log('Login failed');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrors(error.response.data);
    }
  };

  return (
    <div className="LoginForm">
      <h2 className="LoginForm__title">
        Confirm email
        <span style={{ right: '-100px' }} className='closeForm' onClick={() => setIsEmailConfirmationFormVisible(false)}>x</span>
      </h2>
      <form className="LoginForm__form" method='post' onSubmit={handleSubmit}>
        <div className="EmailConfirmationForm__input-group">
          {code.map((data, index) => {
            return (
              <input
                key={index}
                type="text"
                name="code"
                maxLength="1"
                className="EmailConfirmationForm__input"
                value={data}
                onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select()}
              />
            );
          })}
        </div>
        {errors.code && <p style={{textAlign: 'center'}} className="ErrorText">{errors.code[0]}</p>}
        <button type="submit" className="LoginForm__submit">Submit</button>
      </form>
    </div>
  );
};


export default EmailConfirmationForm;