import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './WareHousesCatalog.scss';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { Pagination } from '../../../components/Pagination/Pagination';
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage';
import star from './images/star.png';
import {Link} from 'react-router-dom'

const LoadingIndicator = () => <></>;

export const WareHousesCatalog = ({ wareHouseId, isMyWarehouse, productFilter }) => {
  const [goods, setGoods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const itemsPerPage = 9;

  useEffect(() => {
    const getGoods = async (url) => {
      try {
        setLoading(true);
        const response = await requestInstance.get(url);
        console.log(response.data.results);
        setGoods(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count);
      } catch (error) {
        console.error('Failed to fetch goods:', error);
      } finally {
        setLoading(false);
      }
    };
    if (wareHouseId) {
      let initialUrl = `${BASE_URL}products/?warehouse=${wareHouseId}&limit=${itemsPerPage}`;
      if (productFilter) {
        initialUrl += `&category=${productFilter}`;
      }
      getGoods(initialUrl);
    }
  }, [wareHouseId, productFilter]);

  const mainPhoto = (item) => item.images.find(img => img.main)?.image;

  const handleEditProduct = (productId) => {
    navigate('/profile', { state: { currentForm: 'productForm', productId } });
  };

  return (
    <>
      <div className='WareHousesCatalog'>
        {loading ? (
          <LoadingIndicator />
        ) : (
          goods.length > 0 ? (
            goods.map((product) => (
              <div key={product.id} className='ProductCard'>
                <Link to={`/product/${product.id}`}>
                  <img className='WareHousesCatalog__product_img' src={mainPhoto(product)} alt={product.name} />
                </Link>
                <div className='WareHousesCatalog__rating'><img src={star} alt='src' /> {product.rating}</div>
                <p className='WareHousesCatalog__text'>{product.name}</p>
                <p className='WareHousesCatalog__text'>{product.makers} /{product.alcohol_percentage}%/ {product.production_year}</p>
                <p className='WareHousesCatalog__price'>{product.currency} {parseFloat(product.price).toFixed(2)}</p>
                {isMyWarehouse ? (
                  <button onClick={() => handleEditProduct(product.id)}>Edit product</button>
                ) : (
                  <button>Buy</button>
                )}
              </div>
            ))
          ) : (
            <EmptyPage text="No available goods!" />
          )
        )}
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        setObjects={setGoods}
        setNextPage={setNextPage}
        setPrevPage={setPrevPage}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        count={count}
      />
    </>
  );
};

export default WareHousesCatalog;