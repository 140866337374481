import './ProfileSideBar.scss';
import replaceImg from './images/replace.png'
import profileImg from './images/profile.png'
import productImg from './images/products.png'
// import ordersImg from './images/orders.png'
import subscriptionImg from './images/subscription.png'
import passwordImg from './images/password.png'
import billingImg from './images/billing.png'
import warehousesImg from './images/warehouses.png'
import cocktailsImg from './images/cocktails.png'
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { useState, useRef } from 'react';
import { BASE_URL } from '../../../api/constants';
import { useUser } from '../../../context/UserContext';
import classNames from 'classnames';


export const ProfileSideBar = ( { setCurrentForm, currentForm } ) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { setUser } = useUser();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const formData = new FormData();
      formData.append('user_photo', file);

      try {
        const response = await requestInstance.patch(`${BASE_URL}company/change-user-photo/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const user = JSON.parse(localStorage.getItem('user'));
        user.user_photo = response.data.user_photo;
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user)
      } catch (error) {
        console.error('Error uploading file:', error);
        console.log(selectedFile)
      }
    }
  };


  return (
    <>
  <div className='ProfileSideBar'>
    <div className='ProfileSideBar__header'>
      <h1 className='ProfileSideBar__title'>My profile</h1>    
      <img className='ProfileSideBar__img' src={JSON.parse(localStorage.getItem('user')).user_photo} alt='user' />
        <div className='ProfileSideBar__reset_photo' onClick={handleButtonClick}>
          <img className='ProfileSideBar__icon' src={replaceImg} alt='replace' />
          <span className='ProfileSideBar__replace'>Replace photo</span>
          </div>  
          <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <div className='ProfileSideBar__footer'>
    <ul className='ProfileSideBar__list'>
        <li 
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': currentForm === 'company'})}
          onClick={() => setCurrentForm('company')}>
          <img src={profileImg} alt='profile' className='ProfileSideBar__icon'/>Account
        </li>
        {/* <li 
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': currentForm === 'orders'})}
          onClick={() => setCurrentForm('orders')}>
          <img src={ordersImg} alt='orders' className='ProfileSideBar__icon' />My orders
        </li> */}
        <li
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': (currentForm === 'products' || currentForm === 'productForm')})}
          onClick={() => setCurrentForm('products')}>
          <img src={productImg} alt='products' className='ProfileSideBar__icon' />My Products
        </li>
        <li 
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': currentForm === 'subscription'})}
          onClick={() => setCurrentForm('subscription')}>
          <img src={subscriptionImg} alt='subscription' className='ProfileSideBar__icon' />My Subscription
        </li>
        <li 
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': currentForm === 'password'})}
          onClick={() => setCurrentForm('password')}>
          <img src={passwordImg} alt='password' className='ProfileSideBar__icon'/>Password
        </li>
        <li 
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': currentForm === 'billing'})}
          onClick={() => setCurrentForm('billing')}>
          <img src={billingImg} alt='billing' className='ProfileSideBar__icon' />Billing
        </li>
        <li 
          className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen': (currentForm === 'warehouses' || currentForm === 'warehouseForm' || currentForm === 'teamMemberForm')})}
          onClick={() => setCurrentForm('warehouses')}>
          <img src={warehousesImg} alt='warehouses' className='ProfileSideBar__icon'/>My WareHouses
            </li>
          <li 
        className={classNames({ 'ProfileSideBar__button': true, 'ProfileSideBar__chousen--secend': (currentForm === 'cocktails' || currentForm === 'cocktailsForm')})}
        onClick={() => setCurrentForm('cocktails')}>
        <img src={cocktailsImg} alt='cocktails' className='ProfileSideBar__icon'/>My Cocktails
          </li>
            
        </ul>
      </div>
    </div>
  </>
  )
}

export default ProfileSideBar;