import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CocktailForm.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';

export const CocktailForm = ({ setCurrentForm, cocktailId = null }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const companyId = user ? user.company.id : null;

  const initialCocktailState = {
    picture: null,
    title: '',
    description: '',
    video: null,
    ingredients: [],
    // company_id: companyId,
    company: companyId,
  };

  const [cocktail, setCocktail] = useState(initialCocktailState);
  const [ingredientName, setIngredientName] = useState('');

  useEffect(() => {
    if (cocktailId) {
      const fetchData = async () => {
        try {
          const cocktailUrl = `${BASE_URL}ware-houses/cocktails/${cocktailId}/`;
          const cocktailResponse = await requestInstance.get(cocktailUrl);
          setCocktail(cocktailResponse.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [cocktailId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCocktail({ ...cocktail, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCocktail({ ...cocktail, [name]: files[0] });
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('title', cocktail.title);
    formData.append('description', cocktail.description);
    // formData.append('company_id', cocktail.company_id);
    formData.append('company', cocktail.company);


    if (typeof cocktail.picture !== 'string') {
      formData.append('picture', cocktail.picture);
    }

    if (cocktail.video && typeof cocktail.video !== 'string') {
      formData.append('video', cocktail.video);
    }

    formData.append('ingredients', JSON.stringify(cocktail.ingredients));

    try {
      let response;
      if (cocktailId) {
        response = await requestInstance.patch(`${BASE_URL}ware-houses/cocktails/${cocktailId}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        response = await requestInstance.post(`${BASE_URL}ware-houses/cocktails/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      console.log(response)
      toast.success('Cocktail saved successfully!');
      setCurrentForm('cocktails')
    } catch (error) {
      console.error(error);
      handleErrors(error);
    }
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 400 && error.response.data) {
      const errorMessages = error.response.data;
      for (const field in errorMessages) {
        if (Array.isArray(errorMessages[field])) {
          errorMessages[field].forEach(message => {
            toast.error(`${field}: ${message}`);
          });
        } else {
          toast.error(`${field}: ${errorMessages[field]}`);
        }
      }
    } else {
      toast.error('Failed to save cocktail.');
    }
  };

  const handleAddIngredient = () => {
    if (ingredientName) {
      setCocktail({
        ...cocktail,
        ingredients: [...cocktail.ingredients, { name: ingredientName }]
      });
      setIngredientName('');
    }
  };

  const handleRemoveIngredient = (index) => {
    const updatedIngredients = cocktail.ingredients.filter((_, i) => i !== index);
    setCocktail({ ...cocktail, ingredients: updatedIngredients });
  };

  return (
    <div className='CocktailForm'>
      <ToastContainer />
      <div className='CocktailForm__header'>
        <h2 className='CocktailForm__title'>{cocktailId ? 'Update Cocktail' : 'Create Cocktail'}</h2>
        <div className='CocktailForm__header__buttons'>
          <button className='CocktailForm__header__button CocktailForm__header__button--cancel' onClick={() => setCurrentForm('cocktails')}>Back</button>
          <button className='CocktailForm__header__button CocktailForm__header__button--save' onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className='CocktailForm__section'>
        <div className='CocktailForm__row'>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Title</h3>
            <input className='CocktailForm__input' name='title' value={cocktail.title} onChange={handleChange} />
          </div>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Description</h3>
            <textarea className='CocktailForm__input' name='description' value={cocktail.description} onChange={handleChange} />
          </div>
        </div>
        <div className='CocktailForm__row'>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Picture</h3>
            <input className='CocktailForm__input' type='file' name='picture' onChange={handleFileChange} />
          </div>
          {/* <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Video</h3>
            <input className='CocktailForm__input' type='file' name='video' onChange={handleFileChange} />
          </div> */}
        </div>
        <div className='CocktailForm__row CocktailForm__field--long'>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Ingredients</h3>
            <div className='CocktailForm__ingredients'>
              <input
                className='CocktailForm__input'
                type='text'
                name='ingredient'
                value={ingredientName}
                onChange={(e) => setIngredientName(e.target.value)}
                placeholder='Add new ingredient'
              />
              <button className='CocktailForm__add-button' onClick={handleAddIngredient}>Add</button>
            </div>
            <ul className='CocktailForm__ingredients-list'>
              {cocktail.ingredients.map((ingredient, index) => (
                <li key={index} className='CocktailForm__ingredient-item'>
                  {ingredient.name}
                  <button className='CocktailForm__remove-button' onClick={() => handleRemoveIngredient(index)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CocktailForm;
